import React, { useState,useEffect } from "react";
import { Link,useLocation } from "react-router-dom";
import HeroSection from "./HeroSection";
import ss from "../assets/imgs/ss2.png";
import invoice from "../assets/imgs/invoice.gif";
function LandingPage() {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const location = useLocation();
  const toggleAccordion = (index) => {
    setActiveAccordion(index === activeAccordion ? null : index);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Adds smooth scrolling
    });
  };
  
  useEffect(() => {
    // Check if there's a hash in the URL
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        // Scroll to the element with the ID matching the hash
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const faqData = [
    {
      question: "Can I cancel my subscription at any time?",
      answer:
        "Yes, you can cancel your subscription at any time. While no questions are asked upon cancellation, we would highly appreciate your feedback to help us improve our services.",
    },
    {
      question: "How do I use the credits purchased by my team?",
      answer:
        "Once your team signs up for a subscription plan, you can allocate and utilize the credits for various PDF and document generation tasks. Simply navigate to the credits section in your account to manage and use your credits.",
    },
    {
      question: "How does your pricing work?",
      answer:
        "Our pricing is based on tiered subscription plans. Each plan offers different levels of access to our PDF and document generation tools. Choose the plan that best fits your needs and upgrade as necessary.",
    },
    {
      question: "How secure is my data?",
      answer:
        "Protecting your data is our top priority. We employ robust security measures to ensure that your documents and personal information are safe and secure throughout the conversion and generation processes.",
    },
    {
      question: "How do I access a tool or template I purchased?",
      answer:
        "If you lose access to a tool or template you purchased, don’t worry! You can log in to your account, click on your avatar in the upper right corner, and select Purchases to retrieve your items. If you didn’t create an account or can’t remember your login details, use our Redownload page with the email you used for the purchase.",
    },
    {
      question: "Can I upgrade my license type?",
      answer:
        "Yes, you can upgrade your license type at any time. We offer solutions that allow you to apply your original purchase cost towards the new license, ensuring a seamless transition and access to additional features.",
    },
    {
      question: "What types of documents can I generate?",
      answer:
        "Our platform allows you to generate a variety of documents including invoices, sales receipts, purchase receipts, quotes, memos, and bills. Each template is designed to be easily customizable to meet your specific business needs.",
    },
    {
      question: "Can I merge multiple PDFs into one file?",
      answer:
        "Absolutely! Our Merge PDF tool allows you to combine multiple PDF files into a single document. This is perfect for organizing and managing your PDFs more efficiently.",
    },
    {
      question: "Is it possible to encrypt my PDFs?",
      answer:
        "Yes, you can encrypt your PDFs using our Encrypt PDF tool. This adds a layer of security by requiring a password to open the document, ensuring that only authorized users can access your sensitive information.",
    },
    {
      question: "How do I decrypt an encrypted PDF?",
      answer:
        "To decrypt an encrypted PDF, use our Decrypt PDF tool. Simply upload the encrypted file and enter the correct password to remove the encryption and access the document.",
    },
  ];

  return (
    <>
      <HeroSection/>

      {/* PDF Tools */}
      <div className="max-w-7xl mx-auto pb-24 space-y-8">
        <div className="max-w-7xl mx-auto mt-32 flex flex-col items-start justify-center">
          <div class="w-full flex items-center justify-center flex-col gap-5 mb-14">
            <span class="bg-indigo-50 text-indigo-500 text-xs font-medium px-3.5 py-1 rounded-full">
              PDF Tools
            </span>
            <h1 className="font-light  capitalize  md:text-5xl">
              <span className=" font-semibold ">
                Powerfull{" "}
                <span className="bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
                  {" "}
                  PDF Tools{" "}
                </span>{" "}
                with tons of variety!
              </span>
            </h1>
            <p class="text-lg font-normal text-gray-500 max-w-3xl mx-auto text-center">
              {" "}
              In the world of architecture or organization, structure provides
              the backbone for a purposeful and harmonious existence.
            </p>
          </div>
        </div>
        <>
          <div class="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">
            <div class="h-44 rounded-lg">
              <Link
                to="/home/view-pdf"
                className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-blue"
              >
                <div className="flex flex-col items-center justify-center p-4">
                  <div className="rounded-full">
                    <i className="fa-duotone fa-file-pdf fa-2xl text-blue-600"></i>
                  </div>
                  <div className="mt-6 text-center">
                    <h1 className="text-lg font-semibold">PDF Viewer</h1>
                    <p className="text-sm">View PDF and Print it ASAP.</p>
                  </div>
                </div>
              </Link>
            </div>

            <div class="h-44 rounded-lg">
              <Link
                to="/home/split-pdf"
                className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-red"
              >
                <div className="flex flex-col items-center justify-center p-4">
                  <div className="rounded-full">
                    <i className="fa-duotone fa-file-dashed-line fa-2xl text-red-600"></i>
                  </div>
                  <div className="mt-6 text-center">
                    <h1 className="text-lg font-semibold">Split PDF</h1>
                    <p className="text-sm">Split a PDF into seperate files.</p>
                  </div>
                </div>
              </Link>
            </div>

            <div class="h-44 rounded-lg">
              <Link
                to="/home/merge-pdf"
                className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-yellow"
              >
                <div className="flex flex-col items-center justify-center p-4">
                  <div className="rounded-full">
                    <i className="fa-duotone fa-merge fa-2xl text-yellow-600"></i>
                  </div>
                  <div className="mt-6 text-center">
                    <h1 className="text-lg font-semibold">Merge PDF</h1>
                    <p className="text-sm">
                      Merge multple PDFs from smaller chunks{" "}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div class="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">
            <div class="h-44 rounded-lg">
              <Link
                to="/home/json-to-pdf"
                className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-green"
              >
                <div className="flex flex-col items-center justify-center p-4">
                  <div className="rounded-full">
                    <i className="fa-duotone fa-file-code fa-2xl text-green-600"></i>
                  </div>
                  <div className="mt-6 text-center">
                    <h1 className="text-lg font-semibold">JSON to PDF</h1>
                    <p className="text-sm">Create, Read, Fill Forms</p>
                  </div>
                </div>
              </Link>
            </div>

            <div class="h-44 rounded-lg ">
              <Link
                to="/home/watermark"
                className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-blue"
              >
                <div className="flex flex-col items-center justify-center p-4">
                  <div className="rounded-full">
                    <i className="fa-duotone fa-file-word fa-2xl text-blue-600"></i>
                  </div>
                  <div className="mt-6 text-center">
                    <h1 className="text-lg font-semibold">Add Watermark</h1>
                    <p className="text-sm">Add Watermarks to you PDFs</p>
                  </div>
                </div>
              </Link>
            </div>

            <div class="h-44 rounded-lg">
              <Link
                to="/home/ocr"
                className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-blue"
              >
                <div className="flex flex-col items-center justify-center p-4">
                  <div className="rounded-full">
                    <i className="fa-duotone fa-scanner-image fa-2xl text-blue-600"></i>
                  </div>
                  <div className="mt-6 text-center">
                    <h1 className="text-lg font-semibold">OCR</h1>
                    <p className="text-sm">Extract Text from PDFs</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div class="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">
            <div class="h-44 rounded-lg">
              <Link
                to="/home/encrypt-pdf"
                className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-blue"
              >
                <div className="flex flex-col items-center justify-center p-4">
                  <div className="rounded-full">
                    <i className="fa-duotone fa-file-shield fa-2xl text-blue-600"></i>
                  </div>
                  <div className="mt-6 text-center">
                    <h1 className="text-lg font-semibold">Encrypt PDF</h1>
                    <p className="text-sm px-2">
                      Encrypt a PDF into meaningless form.
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div class="h-44 rounded-lg">
              <Link
                to="/home/decrypt-pdf"
                className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-yellow"
              >
                <div className="flex flex-col items-center justify-center p-4">
                  <div className="rounded-full">
                    <i className="fa-duotone fa-file-circle-check fa-2xl text-yellow-600"></i>
                  </div>
                  <div className="mt-6 text-center">
                    <h1 className="text-lg font-semibold">Decrypt PDF</h1>
                    <p className="text-sm">See your Encrypted PDF.</p>
                  </div>
                </div>
              </Link>
            </div>

            <div class="h-44 rounded-lg">
              <Link
                to="/home/lock-pdf"
                className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-blue"
              >
                <div className="flex flex-col items-center justify-center p-4">
                  <div className="rounded-full">
                    <i className="fa-duotone fa-file-lock fa-2xl text-blue-600"></i>
                  </div>
                  <div className="mt-6 text-center">
                    <h1 className="text-lg font-semibold">Lock PDF</h1>
                    <p className="text-sm px-2">
                      Secure a PDF by adding Password over it.
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </>
        {/* ======================================================== */}
      </div>

      {/* Docments */}
      <section className="max-w-7xl mx-auto bg-white my-12">
        <div className="container px-6 py-10 mx-auto overflow-x-hidden">
          <div class="flex items-center justify-center flex-col gap-5 mb-14">
            <span class="bg-indigo-50 text-indigo-500 text-xs font-medium px-3.5 py-1 rounded-full">
              Document Generators
            </span>
            <h1 className="font-light  capitalize  md:text-5xl">
              Seamless{" "}
              <span className="underline font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
                Invoice & Reciept
              </span>{" "}
              Generator!
            </h1>
            <p class="text-lg font-normal text-gray-500 max-w-3xl mx-auto text-center">
              {" "}
              In the world of architecture or organization, structure provides
              the backbone for a purposeful and harmonious existence.
            </p>
          </div>

          <div className="grid grid-cols-1 mt-8 gap-4 lg:grid-cols-3 lg:gap-8">
            <div class="h-52 rounded-lg">
              <div>
                <Link
                  to="/home/generate-docx/invoice-data"
                  className="group py-4 flex flex-co items-center justify-center h-full bg-white border-t border-l border-r shadow-sm rounded-tl-xl rounded-tr-xl hover:shadow-md transition ripple-blue"
                >
                  <div className="flex flex-col items-center justify-center p-4">
                    <div className="rounded-full">
                      <i className="fa-duotone fa-file-invoice fa-2xl text-blue-600"></i>
                    </div>
                    <div className="mt-6 text-center">
                      <h1 className="text-lg font-semibold">
                        Generate Invoice
                      </h1>
                      <p className="text-sm">
                        Effortless Invoices, Seamless Business
                      </p>
                    </div>
                  </div>
                </Link>
              </div>

              <div>
                <Link
                  to="/home/generate-docx/invoice-data"
                  className="group p-3 rounded-bl-xl rounded-br-xl border flex flex-co items-center justify-center h-full shadow-sm rounded-xl-t hover:shadow-md transition ripple-blue"
                >
                  Create Now
                </Link>
              </div>
            </div>

            <div class="h-52 rounded-lg">
              <div>
                <Link
                  to="/home/generate-docx/sales-reciept-form"
                  className="group py-4 flex flex-co items-center justify-center h-full bg-white border-t border-l border-r shadow-sm rounded-tl-xl rounded-tr-xl hover:shadow-md transition ripple-red"
                >
                  <div className="flex flex-col items-center justify-center p-4">
                    <div className="rounded-full">
                      <i className="fa-duotone fa-receipt fa-2xl text-red-600"></i>
                    </div>
                    <div className="mt-6 text-center">
                      <h1 className="text-lg font-semibold">
                        Generate Sales Receipt
                      </h1>
                      <p className="text-sm">
                        Streamlined Receipts, Simplified Sales
                      </p>
                    </div>
                  </div>
                </Link>
              </div>

              <div>
                <Link
                  to="/home/generate-docx/sales-reciept-form"
                  className="group p-3 rounded-bl-xl rounded-br-xl border flex flex-co items-center justify-center h-full shadow-sm rounded-xl-t hover:shadow-md transition ripple-red"
                >
                  Create Now
                </Link>
              </div>
            </div>

            <div class="h-52 rounded-lg">
              <div>
                <Link
                  to="/home/generate-docx/bills-reciept-form"
                  className="group py-4 flex flex-co items-center justify-center h-full bg-white border-t border-l border-r shadow-sm rounded-tl-xl rounded-tr-xl hover:shadow-md transition ripple-yellow"
                >
                  <div className="flex flex-col items-center justify-center p-4">
                    <div className="rounded-full">
                      <i className="fa-duotone fa-solid fa-file-invoice-dollar fa-2xl text-yellow-600"></i>
                    </div>
                    <div className="mt-6 text-center">
                      <h1 className="text-lg font-semibold">Generate Bills</h1>
                      <p className="text-sm">
                        Billing Made Easy, Business Made Simple.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>

              <div>
                <Link
                  to="/home/generate-docx/bills-reciept-form"
                  className="group p-3 rounded-bl-xl rounded-br-xl border flex flex-co items-center justify-center h-full shadow-sm rounded-xl-t hover:shadow-md transition ripple-yellow"
                >
                  Create Now
                </Link>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 mt-8 gap-4 lg:grid-cols-3 lg:gap-8">
            <div class="h-52 rounded-lg">
              <div>
                <Link
                  to="/home/generate-docx/quotes-form"
                  className="group py-4 flex flex-co items-center justify-center h-full bg-white border-t border-l border-r shadow-sm rounded-tl-xl rounded-tr-xl hover:shadow-md transition ripple-green"
                >
                  <div className="flex flex-col items-center justify-center p-4">
                    <div className="rounded-full">
                      <i className="fa-duotone fa-quotes fa-2xl text-green-600"></i>
                    </div>
                    <div className="mt-6 text-center">
                      <h1 className="text-lg font-semibold">Generate Quotes</h1>
                      <p className="text-sm">
                        Precision Quotes, Profitable Deals
                      </p>
                    </div>
                  </div>
                </Link>
              </div>

              <div>
                <Link
                  to="/home/generate-docx/quotes-form"
                  className="group p-3 rounded-bl-xl rounded-br-xl border flex flex-co items-center justify-center h-full shadow-sm rounded-xl-t hover:shadow-md transition ripple-green"
                >
                  Create Now
                </Link>
              </div>
            </div>

            <div class="h-52 rounded-lg">
              <div>
                <Link
                  to="/home/generate-docx/purchase-form"
                  className="group py-4 flex flex-co items-center justify-center h-full bg-white border-t border-l border-r shadow-sm rounded-tl-xl rounded-tr-xl hover:shadow-md transition ripple-red"
                >
                  <div className="flex flex-col items-center justify-center p-4">
                    <div className="rounded-full">
                      <i className="fa-duotone fa-receipt fa-2xl text-red-600"></i>
                    </div>
                    <div className="mt-6 text-center">
                      <h1 className="text-lg font-semibold">
                        Generate Purchase Receipt
                      </h1>
                      <p className="text-sm">
                        Streamlined Receipts, Simplified Purchase
                      </p>
                    </div>
                  </div>
                </Link>
              </div>

              <div>
                <Link
                  to="/home/generate-docx/purchase-form"
                  className="group p-3 rounded-bl-xl rounded-br-xl border flex flex-co items-center justify-center h-full shadow-sm rounded-xl-t hover:shadow-md transition ripple-red"
                >
                  Create Now
                </Link>
              </div>
            </div>

            <div class="h-52 rounded-lg">
              <div>
                <Link
                  to="/home/generate-docx/memo-form"
                  className="group py-4 flex flex-co items-center justify-center h-full bg-white border-t border-l border-r shadow-sm rounded-tl-xl rounded-tr-xl hover:shadow-md transition ripple-blue"
                >
                  <div className="flex flex-col items-center justify-center p-4">
                    <div className="rounded-full">
                      <i className="fa-duotone fa-memo-circle-info fa-2xl text-purple-600"></i>
                    </div>
                    <div className="mt-6 text-center">
                      <h1 className="text-lg font-semibold">Generate Memo</h1>
                      <p className="text-sm">
                        Crisp Memos, Clear Communication
                      </p>
                    </div>
                  </div>
                </Link>
              </div>

              <div>
                <Link
                  to="/home/generate-docx/memo-form"
                  className="group p-3 rounded-bl-xl rounded-br-xl border flex flex-co items-center justify-center h-full shadow-sm rounded-xl-t hover:shadow-md transition ripple-blue"
                >
                  Create Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features */}
      <section class="py-24">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="flex items-center justify-center flex-col gap-5 mb-14">
            <span class="bg-indigo-50 text-indigo-500 text-xs font-medium px-3.5 py-1 rounded-full">
              Features
            </span>
            <h1 className="font-light  capitalize  md:text-5xl">
              Revolutionary{" "}
              <span className="underline font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
                PDF Tools
              </span>{" "}
              Features!
            </h1>
            <p class="text-lg font-normal text-gray-500 max-w-3xl mx-auto text-center">
              {" "}
              In the world of architecture or organization, structure provides
              the backbone for a purposeful and harmonious existence.
            </p>
          </div>

          <div class="flex justify-center items-center gap-x-5 gap-y-8 lg:gap-y-0 flex-wrap md:flex-wrap lg:flex-nowrap lg:flex-row lg:justify-between lg:gap-x-8">
            <div class="relative w-full text-center max-md:max-w-sm max-md:mx-auto group md:w-2/5 lg:w-1/4">
              <div class="bg-indigo-50 rounded-lg flex justify-center items-center mb-5 w-20 h-20 mx-auto cursor-pointer transition-all duration-500">
                <i class="fa-sharp-duotone fa-solid fa-database fa-2xl text-blue-600"></i>
              </div>
              <h4 class="text-lg font-medium text-gray-900 mb-3 capitalize">
                Get Secure Storage
              </h4>
              <p class="text-sm font-normal text-gray-500">
                plan and structure work how you want. Quickly organizing tasks.
              </p>
            </div>
            <div class="relative w-full text-center max-md:max-w-sm max-md:mx-auto group md:w-2/5 lg:w-1/4">
              <div class="bg-pink-50 rounded-lg flex justify-center items-center mb-5 w-20 h-20 mx-auto cursor-pointer transition-all duration-500">
                <i class="fa-solid fa-file-shield fa-2xl text-pink-600"></i>
              </div>
              <h4 class="text-lg font-medium text-gray-900 mb-3 capitalize">
                Crackless Encryption
              </h4>
              <p class="text-sm font-normal text-gray-500">
                Bring all your tools and data together. Also join with hundreds
                of other apps.
              </p>
            </div>
            <div class="relative w-full text-center max-md:max-w-sm max-md:mx-auto group md:w-2/5 lg:w-1/4">
              <div class="bg-teal-50 rounded-lg flex justify-center items-center mb-5 w-20 h-20 mx-auto cursor-pointer transition-all duration-500">
                <i class="fa-regular fa-microchip-ai fa-2xl text-emerald-600"></i>
              </div>
              <h4 class="text-lg font-medium text-gray-900 mb-3 capitalize">
                AI Integrated OCR
              </h4>
              <p class="text-sm font-normal text-gray-500">
                Get real time insight into progress and allows teams to track
                their work habits
              </p>
            </div>
            <div class="relative w-full text-center max-md:max-w-sm max-md:mx-auto group md:w-2/5 lg:w-1/4">
              <div class="bg-orange-50 rounded-lg flex justify-center items-center mb-5 w-20 h-20 mx-auto cursor-pointer transition-all duration-500">
                <i class="fa-solid fa-arrow-progress fa-2xl text-orange-600"></i>
              </div>
              <h4 class="text-lg font-medium text-gray-900 mb-3 capitalize">
                Flexible PDF Tools
              </h4>
              <p class="text-sm font-normal text-gray-500">
                Automated processes to coordinate your teams and increase
                communication.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="h-full">
        <div class="relative mx-auto">
          <div class="absolute inset-0 bottom-[32rem] bg-gradient-to-b from-indigo-700 via-blue-700 to-blue-600"></div>
          <div class="relative pt-20 mx-auto w-full max-w-screen-xl px-2 text-left sm:px-10">
            <div class="rounded-xl bg-white shadow-2xl shadow-blue-200 lg:flex">
              <div class="flex flex-col p-4 pb-6 sm:p-10 sm:pt-14 lg:w-2/5">
                <div class="flex-auto">
                  <div class="flex items-center justify-center flex-col gap-5 mb-14">
                    <h1 className="font-light  capitalize  md:text-3xl">
                      Revolutionary{" "}
                      <span className="underline font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
                        Document Generator
                      </span>{" "}
                      Features!
                    </h1>
                    <p class="text-lg font-normal text-gray-500 max-w-3xl mx-auto text-start">
                      {" "}
                      In the world of architecture or organization, structure
                      provides the backbone for a purposeful and harmonious
                      existence.
                    </p>
                  </div>

                  <section>
                    <div class="group relative flex gap-x-5">
                      <div class="relative group-last:after:hidden after:absolute after:top-8 after:bottom-2 after:start-3 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                        <div class="relative z-10 size-6 flex justify-center items-center">
                          <i class="fa-solid fa-file-invoice fa-2xl text-blue-600"></i>
                        </div>
                      </div>

                      <div class="grow pb-8 group-last:pb-0">
                        <h3 class="mb-1 text-xs text-gray-600">QuickDoc</h3>

                        <p class="font-semibold text-sm text-gray-800">
                          Generate Invoices
                        </p>

                        <p class="mt-1 text-sm text-gray-600">
                          The company has high expectations and using Orstanding
                          of expectations and performance.
                        </p>
                      </div>
                    </div>

                    <div class="group relative flex gap-x-5">
                      <div class="relative group-last:after:hidden after:absolute after:top-8 after:bottom-2 after:start-3 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                        <div class="relative z-10 size-6 flex justify-center items-center">
                          <i class="fa-solid fa-regular fa-globe-pointer fa-2xl text-blue-600"></i>
                        </div>
                      </div>

                      <div class="grow pb-8 group-last:pb-0">
                        <h3 class="mb-1 text-xs text-gray-600">QuickDoc</h3>

                        <p class="font-semibold text-sm text-gray-800">
                          Share via Link
                        </p>

                        <p class="mt-1 text-sm text-gray-600">
                          This is an excellent company and the the growth I
                          think, but everyone has to. Great place to work.
                        </p>
                      </div>
                    </div>

                    <div class="group relative flex gap-x-5">
                      <div class="relative group-last:after:hidden after:absolute after:top-8 after:bottom-2 after:start-3 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                        <div class="relative z-10 size-6 flex justify-center items-center">
                          <i class="fa-solid fa-print-magnifying-glass fa-2xl text-blue-600"></i>
                        </div>
                      </div>

                      <div class="grow pb-8 group-last:pb-0">
                        <h3 class="mb-1 text-xs text-gray-600">QuickDoc</h3>

                        <p class="font-semibold text-sm text-gray-800">
                          Save and Print
                        </p>

                        <p class="mt-1 text-sm text-gray-600">
                          This is an excellent company and the the growth I
                          think, but everyone has to. Great place to work.
                        </p>
                      </div>
                    </div>

                    <div class="group relative flex gap-x-5">
                      <div class="relative group-last:after:hidden after:absolute after:top-8 after:bottom-2 after:start-3 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                        <div class="relative z-10 size-6 flex justify-center items-center">
                          <i class="fa-solid fa-rectangle-history-circle-user fa-2xl text-blue-600"></i>
                        </div>
                      </div>

                      <div class="grow pb-8 group-last:pb-0">
                        <h3 class="mb-1 text-xs text-gray-600">QuickDoc</h3>

                        <p class="font-semibold text-sm text-gray-800">
                          Track Invoices History
                        </p>

                        <p class="mt-1 text-sm text-gray-600">
                          This is an excellent company and the the growth I
                          think, but everyone has to. Great place to work.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="m-8 lg:w-3/5 hidden overflow-hidden rounded-xl sm:block">
                <img class="object-cover w-full h-full" src={ss} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="max-w-7xl py-24 px-4 sm:px-6 lg:px-8 mx-auto">
        {/* Grid */}
        <div className="lg:grid lg:grid-cols-12 lg:gap-16 lg:items-center lg:justify-between">
          <div className="lg:col-span-5 lg:col-start-1">
            {/* Title */}
            <div className="mb-8">
              <h2 className="mb-2 text-3xl text-gray-800 font-bold lg:text-4xl">
                It's all about efficiency
              </h2>
              <p className="text-gray-600">
                We provide you with tools that can be set up in seconds. Our
                main focus is delivering fast and reliable document conversion
                and generation solutions.
              </p>
            </div>
            {/* End Title */}
            {/* Blockquote */}
            <blockquote className="relative">
              <svg
                className="absolute top-0 start-0 transform -translate-x-6 -translate-y-8 size-16 text-gray-200"
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M7.39762 10.3C7.39762 11.0733 7.14888 11.7 6.6514 12.18C6.15392 12.6333 5.52552 12.86 4.76621 12.86C3.84979 12.86 3.09047 12.5533 2.48825 11.94C1.91222 11.3266 1.62421 10.4467 1.62421 9.29999C1.62421 8.07332 1.96459 6.87332 2.64535 5.69999C3.35231 4.49999 4.33418 3.55332 5.59098 2.85999L6.4943 4.25999C5.81354 4.73999 5.26369 5.27332 4.84476 5.85999C4.45201 6.44666 4.19017 7.12666 4.05926 7.89999C4.29491 7.79332 4.56983 7.73999 4.88403 7.73999C5.61716 7.73999 6.21938 7.97999 6.69067 8.45999C7.16197 8.93999 7.39762 9.55333 7.39762 10.3ZM14.6242 10.3C14.6242 11.0733 14.3755 11.7 13.878 12.18C13.3805 12.6333 12.7521 12.86 11.9928 12.86C11.0764 12.86 10.3171 12.5533 9.71484 11.94C9.13881 11.3266 8.85079 10.4467 8.85079 9.29999C8.85079 8.07332 9.19117 6.87332 9.87194 5.69999C10.5789 4.49999 11.5608 3.55332 12.8176 2.85999L13.7209 4.25999C13.0401 4.73999 12.4903 5.27332 12.0713 5.85999C11.6786 6.44666 11.4168 7.12666 11.2858 7.89999C11.5215 7.79332 11.7964 7.73999 12.1106 7.73999C12.8437 7.73999 13.446 7.97999 13.9173 8.45999C14.3886 8.93999 14.6242 9.55333 14.6242 10.3Z"
                  fill="currentColor"
                />
              </svg>
              <div className="relative z-10">
                <p className="text-xl italic text-gray-800">
                  Fantastic tools that make document management a breeze. Very
                  efficient and professional service.
                </p>
              </div>
              <footer className="mt-6">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="size-8 rounded-full"
                      src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                      alt="Image Description"
                    />
                  </div>
                  <div className="grow ms-4">
                    <div className="font-semibold text-gray-800">
                      Josh Grazioso
                    </div>
                    <div className="text-xs text-gray-500">
                      Director of Operations | Tech Solutions
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
            {/* End Blockquote */}
          </div>
          {/* End Col */}
          <div className="mt-10 lg:mt-0 lg:col-span-6 lg:col-end-13">
            <div className="space-y-6 sm:space-y-8">
              {/* List */}
              <ul className="grid grid-cols-2 divide-y-2 divide-x-2 divide-gray-200 overflow-hidden">
                <li className="flex flex-col -m-0.5 p-4 sm:p-8">
                  <div className="flex items-end gap-x-2 text-3xl sm:text-5xl font-bold text-gray-800 mb-2">
                    100k+
                  </div>
                  <p className="text-sm sm:text-base text-gray-600">
                    users - from small businesses to large enterprises
                  </p>
                </li>
                <li className="flex flex-col -m-0.5 p-4 sm:p-8">
                  <div className="flex items-end gap-x-2 text-3xl sm:text-5xl font-bold text-gray-800 mb-2">
                    <svg
                      className="flex-shrink-0 size-5 text-blue-600"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m5 12 7-7 7 7" />
                      <path d="M12 19V5" />
                    </svg>
                    35%
                  </div>
                  <p className="text-sm sm:text-base text-gray-600">
                    reduction in document processing time
                  </p>
                </li>
                <li className="flex flex-col -m-0.5 p-4 sm:p-8">
                  <div className="flex items-end gap-x-2 text-3xl sm:text-5xl font-bold text-gray-800 mb-2">
                    <svg
                      className="flex-shrink-0 size-5 text-blue-600"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m5 12 7-7 7 7" />
                      <path d="M12 19V5" />
                    </svg>
                    50%
                  </div>
                  <p className="text-sm sm:text-base text-gray-600">
                    boost in productivity for document handling
                  </p>
                </li>
                <li className="flex flex-col -m-0.5 p-4 sm:p-8">
                  <div className="flex items-end gap-x-2 text-3xl sm:text-5xl font-bold text-gray-800 mb-2">
                    3x
                  </div>
                  <p className="text-sm sm:text-base text-gray-600">
                    faster processing than previous solutions
                  </p>
                </li>
              </ul>
              {/* End List */}
            </div>
          </div>
          {/* End Col */}
        </div>
        {/* End Grid */}
      </section>

      <section id="faqs" className="max-w-7xl px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        {/* Grid */}
        <div className="grid md:grid-cols-5 gap-10">
          <div className="md:col-span-2">
            <div className="max-w-xs">
              <h2 className="text-2xl font-bold md:text-4xl md:leading-tight">
                Frequently
                <br />
                asked questions
              </h2>
              <p className="mt-1 hidden md:block text-gray-600">
                Answers to the most frequently asked questions.
              </p>
            </div>
          </div>
          {/* End Col */}
          <div className="md:col-span-3">
            {/* Accordion */}
            <div className="hs-accordion-group divide-y divide-gray-200">
              {faqData.map((item, index) => (
                <div key={index} className="hs-accordion pt-6 pb-3">
                  <button
                    className="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-start text-gray-800 rounded-lg transition hover:text-gray-500"
                    onClick={() => toggleAccordion(index)}
                    aria-expanded={index === activeAccordion}
                    aria-controls={`hs-accordion-content-${index}`}
                  >
                    {item.question}
                    <i className="fa-regular fa-circle-plus"></i>
                  </button>
                  <div
                    id={`hs-accordion-content-${index}`}
                    className={`hs-accordion-content ${
                      index === activeAccordion ? "block" : "hidden"
                    } w-full overflow-hidden transition-[height] duration-300`}
                    aria-labelledby={`hs-basic-with-title-and-arrow-stretched-heading-${index}`}
                  >
                    <p className="text-gray-600">{item.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* End Col */}
        </div>
        {/* End Grid */}
      </section>

      <section class="py-16">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="lg:py-14 lg:px-20 p-10 rounded-2xl bg-gradient-to-r from-indigo-600 to-violet-600 flex items-center justify-between flex-col lg:flex-row">
            <div class="block text-center mb-5 lg:text-left lg:mb-0">
              <h2 class="font-manrope text-4xl text-white font-medium mb-5 lg:mb-2">
                Connect with us
              </h2>
              <p class="text-xl text-indigo-100">
                Contact us with any query or any idea.
              </p>
            </div>
            <Link
              to="/contact-us"
              class="flex items-center gap-2 bg-white rounded-full shadow-sm text-lg text-indigo-600 font-semibold py-4 px-8 transition-all duration-500"
            >
              Get In Touch
              <svg
                width="19"
                height="14"
                viewBox="0 0 19 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.75 7L16.4167 7M11.8333 12.5L16.6852 7.64818C16.9907 7.34263 17.1435 7.18985 17.1435 7C17.1435 6.81015 16.9907 6.65737 16.6852 6.35182L11.8333 1.5"
                  stroke="#4F46E5"
                  stroke-width="2.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>
        </div>
      </section>

      {/* <div className="z-[99] flex flex-col items-end justify-end space-y-4 fixed bottom-4 right-4">
                <button onClick={scrollToTop} className="bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 p-2 w-10 h-10 rounded-full">
                    <i class="fa-solid fa-arrow-up"></i>
                </button>
            </div> */}
    </>
  );
}

export default LandingPage;
