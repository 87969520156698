import React from "react";
import { Link } from "react-router-dom";
import {
  FileText,
  Hospital,
  Database,
  ArrowRight,
  CheckCircle,
  Users,
  Clock,
  Shield,
  ChevronRight,
} from "lucide-react";
import DMSFeature from "../components/Feature/DMSFeature";
import Overview from "../components/OverView";

const ServiceCard = ({ icon: Icon, text }) => (
  <div className="flex items-center p-4 bg-white rounded-xl shadow-md transition-all duration-300 hover:shadow-lg hover:scale-105 border border-gray-100">
    <Icon className="w-8 h-8 text-indigo-600 mr-4" />
    <span className="text-sm font-semibold text-gray-800">{text}</span>
  </div>
);

const StatCard = ({ icon: Icon, value, label }) => (
  <div className="bg-white p-6 rounded-xl shadow-md flex items-center space-x-4 border border-gray-100">
    <div className="bg-indigo-100 p-3 rounded-full">
      <Icon className="w-8 h-8 text-indigo-600" />
    </div>
    <div>
      <div className="text-3xl font-bold text-gray-800">{value}</div>
      <div className="text-sm font-medium text-gray-600">{label}</div>
    </div>
  </div>
);

const Product = () => {
  const services = [
    { icon: FileText, text: "Document Management System" },
    { icon: Hospital, text: "Hospital Management System" },
    { icon: Database, text: "Oracle APEX Services" },
    { icon: ArrowRight, text: "Migration Services" },
  ];

  const stats = [
    { icon: Users, value: "10,000+", label: "Users" },
    { icon: CheckCircle, value: "99.9%", label: "Uptime" },
    { icon: Clock, value: "24/7", label: "Support" },
    { icon: Shield, value: "ISO 27001", label: "Certified" },
  ];

  return (
    <div className="max-w-[90rem] mx-auto px-4 sm:px-6 lg:px-8">
      {/* Hero Section */}
      <section className="relative py-20 md:py-32 overflow-hidden ">
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCI+CiAgPHBhdGggZD0iTTAgMGg2MHY2MEgweiIgZmlsbD0ibm9uZSIvPgogIDxwYXRoIGQ9Ik0zMCAzMG0tMjggMGEyOCAyOCAwIDEgMCA1NiAwYTI4IDI4IDAgMSAwLTU2IDB6IiBmaWxsPSJub25lIiBzdHJva2U9IiNFOEVBRjYiIHN0cm9rZS13aWR0aD0iMC41Ii8+Cjwvc3ZnPg==')] opacity-20"></div>
        <div className="relative container mx-auto">
          <div className="flex flex-col lg:flex-row items-center">
            <div className="lg:w-1/2 mb-12 lg:mb-0 lg:pr-12">
              <div className="inline-block py-2 px-4 mb-6 text-sm font-semibold text-indigo-800 bg-indigo-100 rounded-full animate-pulse">
                Innovative SaaS Solutions
              </div>
              <h1 className="font-heading text-4xl xs:text-5xl md:text-6xl font-bold mb-6">
                <span>Transform Your</span>
                <span className="text-indigo-600 relative">
                  Business
                  <svg
                    className="absolute -bottom-2 left-0 w-full"
                    viewBox="0 0 358 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 9C118.957 4.47226 364.497 -1.86658 355 9"
                      stroke="#818CF8"
                      strokeWidth="5"
                      strokeLinecap="round"
                    />
                  </svg>
                </span>
                <span>with Our Services</span>
              </h1>
              <p className="text-xl text-gray-700 font-medium mb-10">
                Elevate your operations with our cutting-edge DMS, HMS, and
                Oracle APEX solutions.
              </p>
              <div className="grid grid-cols-2 gap-6 mb-10">
                {stats.map((stat, index) => (
                  <StatCard key={index} {...stat} />
                ))}
              </div>
              <Link
                to="/contact-us"
                className="inline-flex items-center px-8 py-4 bg-gradient-to-r from-indigo-600 to-blue-600 text-white font-semibold rounded-lg hover:from-indigo-700 hover:to-blue-700 transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-indigo-400 shadow-lg"
                type="button"
                aria-label="Get Started"
              >
                Get Started
                <ChevronRight className="ml-2 w-5 h-5" />
              </Link>
            </div>
            <div className="lg:w-1/2">
              <div className="relative">
                <img
                  className="w-full h-auto rounded-2xl transition-transform duration-300 hover:scale-105"
                  src="https://img.freepik.com/premium-vector/two-professionals-engage-with-laptops-focusing-big-data-management-insights-while-surrounded-by-graphs-charts-digital-tools_538213-138026.jpg?ga=GA1.1.1469234575.1726214580&semt=ais_hybrid"
                  alt="SaaS Services Illustration"
                />
                <div className="absolute -bottom-6 -right-6 bg-white p-6 rounded-xl shadow-xl">
                  <div className="flex items-center mb-3">
                    <CheckCircle className="w-6 h-6 text-green-500 mr-3" />
                    <span className="text-sm font-bold text-gray-800">
                      Enterprise-grade Security
                    </span>
                  </div>
                  <div className="flex items-center">
                    <CheckCircle className="w-6 h-6 text-green-500 mr-3" />
                    <span className="text-sm font-bold text-gray-800">
                      Scalable Solutions
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Overview */}
      <Overview />

      {/* Features */}
      <DMSFeature />
    </div>
  );
};

export default Product;
